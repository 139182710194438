class Copyright extends HTMLElement {
	constructor() {
		super();

		if (!this.shadowRoot) return;

		const year = this.shadowRoot.getElementById("year");
		year.innerText = `${new Date().getFullYear()}`;
	}
}

customElements.define("ml-copyright", Copyright);
