class Nav extends HTMLElement {
	constructor() {
		super();

		if (!this.shadowRoot) return;

		const links = this.shadowRoot.querySelectorAll("a[href]");
		const url = new URL(self.location);

		Array.from(links).forEach((link) => {
			const href = new URL(link.getAttribute("href"), self.location);

			if (
				(url.pathname === "/" && href.pathname.startsWith("/index.html")) ||
				url.pathname === href.pathname || url.pathname === href.pathname.split(".").at(0)
			) {
				link.setAttribute("aria-current", "page");
				link.closest("li").classList.add("is-active");
			} else {
				link.removeAttribute("aria-current");
				link.closest("li").classList.remove("is-active");
			}
		});
	}
}

customElements.define("ml-nav", Nav);
